/*
 *
 * UnitRequestFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    description: '',
    notes: '',
    id_pelaporan: 0,
    id_aset: 0,
    nm_aset: "",
    nm_ruangan: "",
    id_branch: 0,
    id_item: 0,
    assets:[],
    pelaporan: null,
};

/* eslint-disable default-case, no-param-reassign */
const WastePickupFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      console.log("reset state");
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_PELAPORAN:
        draft.loading = true;
        draft.id_branch = action.id_branch;
        break;
      case constants.SUBMIT_PELAPORAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_PELAPORAN_SUCCESS:
        draft.id_pelaporan = action.results.id_pelaporan;
        draft.loading = false;
        draft.error = false;
        draft.description = "";
        draft.notes = "";
        draft.assets = [];
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.GET_PELAPORAN:
        draft.loading = true;
        break;
      case constants.GET_PELAPORAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data pelaporan.';
        draft.results = [];
        break;
      case constants.GET_PELAPORAN_SUCCESS:
        draft.pelaporan = action.results;
        draft.description = action.results.description;
        draft.nm_ruangan = action.results.nm_ruang;
        draft.nm_aset = action.results.nm_inventory;
        draft.id_item = action.results.id_ruang;
        draft.id_aset = action.results.id_inventaris;
        // draft.notes = action.results.notes;
        draft.assets = action.attachment;
        draft.loading = false;
        break;
    }
  });

export default WastePickupFormScreenReducer;
