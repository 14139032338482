/*
 *
 * PostAnnounceFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_submit: false,
    isChecked: false,
    username: '',
    email: '',
    no_hp: '',
    address: '',
    name: '',
    no_phone: '',
    results: null,
    id_pengumuman: 0,
    saved_details: []
};

/* eslint-disable default-case, no-param-reassign */
const PostAnnounceFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_PEGAWAI:
        draft.loading = true;
        break;
      case constants.GET_PEGAWAI_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.results = null;
        break;
      case constants.GET_PEGAWAI_SUCCESS:
        draft.results = action.results;
        draft.address = action.results.address;
        draft.no_hp = action.results.hp;
        draft.name = action.results.name_employee;
        draft.no_phone = action.results.phone;
        draft.error = false;
        draft.loading = false;
        break;
      case constants.SUBMIT_PEGAWAI:
        draft.loading_submit = true;
        draft.isChecked = false;
        break;
      case constants.SUBMIT_PEGAWAI_FAIL:
        draft.loading_submit = false;
        draft.isChecked = true;
        draft.error = true;
        break;
      case constants.SUBMIT_PEGAWAI_SUCCESS:
        draft.loading_submit = false;
        draft.isChecked = true;
        draft.error = false;
        break;
    }
  });

export default PostAnnounceFormScreenReducer;
