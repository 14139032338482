import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectPostAnnounceFormScreen from './selector';
import { getPegawaiFail, getPegawaiSuccess, submitPegawaiFail, submitPegawaiSuccess } from './actions';

  export function* doGetPegawai(action) {
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {
      const response = yield call(api.user.getData);
      const {success} = response.data;
  
      yield put(
        getPegawaiSuccess(success),
      );
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getPegawaiFail(err));
    }
  }

  export function* doSubmitPegawai(action) {
    const dataset = yield select(makeSelectPostAnnounceFormScreen());
    try {

      const params = {
        username: dataset.username,
        name: dataset.name,
        address: dataset.address,
        no_hp: dataset.no_hp,
        no_phone: dataset.no_phone,
        email: dataset.email,
      };

      const response = yield call(api.user.submitData, params);
      const {success} = response.data;
      
      if(success){
        const user = api.getUserData();
        const updated_user = {...user, email: dataset.email, hp: dataset.no_hp, user_name: dataset.username}
        api.setUserData(updated_user);
      }

      yield put(
        submitPegawaiSuccess(success),
      );
      helper.successToast("Data berhasil disimpan");
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(submitPegawaiFail(err));
    }
  }
  
  // Individual exports for testing
  export default function* postAnnounceFormScreenSaga() {
    yield all([
      takeLatest(constants.GET_PEGAWAI, doGetPegawai),
      takeLatest(constants.SUBMIT_PEGAWAI, doSubmitPegawai),
    ]);
  }
  