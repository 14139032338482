import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectUnitRequestFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import MySelect2 from '../../components/MySelect2';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, delSavedDetail, setSavedDetail, getPermintaan, submitPermintaan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';
import makeSelectGlobalVar from '../../util/selector';
import MyAlert from '../../components/MyAlert';
import MyAlertDialog from '../../components/MyAlertDialog';
import LoadingOverlay from 'react-loading-overlay';

export function UnitRequestFormScreen({
  dispatch,
  location,
  navigation,
  globalVar,
  unitRequestFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSetDetail,
  onDelDetail,
  onSubmitPermintaan,
  onGetPermintaan
}){
  const {id_permintaan, viewOnly, new_data} = location.state;
  const[userData, setUserData] = useState();
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isOpenDialogSubmit, setOpenDialogSubmit] = useState(false);
  const[idxDetail, setIdxDetail] = useState();

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  const status_detail = {
    '10': 'created',
    '20': 'batal',
    '30': 'approve',
    '40': 'done'
  };


  useEffect(() => {
    console.log('first-state', location.state);

    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
    // reset params
    dispatch(defaultAction());
    onUpdateInput('id_permintaan', id_permintaan);
    //EDIT STATE
    if(!helper.isEmpty(id_permintaan)){
      const curdataReq = api.getFromLocalDB('unitReq');
      curdataReq.then((val) => {
        console.log('saved_permintaan', val);
        if(helper.isEmpty(val) || id_permintaan != val?.id_permintaan){
          // get data if localDB is empty
          onGetPermintaan(id_permintaan);
        } else {
          // get data from localDB
          onUpdateInput('id_unit', val.id_unit);
          onUpdateInput('notes', val.notes);
          onUpdateInput('code_permintaan', val.code_permintaan);
        }
      }).catch(err => {
        console.log('err-localdb:', err)
      });

      const curdataDetail = api.getFromLocalDB('unitReqDetail');
      curdataDetail.then((val) => {
        console.log('new_saved_details', val);
        if(val?.length > 0) onUpdateInput('saved_details',val);
      }).catch(err => {
        console.log('err-localdb:', err)
      });

    //ADD STATE
    } else {
      if(new_data){
        // clear for fresh data
        api.clearItemLocalDB('unitReqDetail');
        api.clearItemLocalDB('unitReq');
      } else {
        // get data from localDB
        const curdataReq = api.getFromLocalDB('unitReq');
        curdataReq.then((val) => {
            // get data from localDB
            onUpdateInput('id_unit', val.id_unit);
            onUpdateInput('notes', val.notes);
        }).catch(err => {
          console.log('err-localdb:', err)
        });

        const curdata = api.getFromLocalDB('unitReqDetail');
        curdata.then((val) => {
          console.log('new_saved_details', val);
          if(val?.length > 0) onUpdateInput('saved_details',val);
        }).catch(err => {
          console.log('err-localdb:', err)
        });
      } 
      
    }
  },[]);


  const handleSubmit = () => {
    if(helper.isEmpty(unitRequestFormScreen.notes) || unitRequestFormScreen.notes.length < 10){
      helper.warningToast("Isikan deskripsi permintaan, Minimal 10 karakter.");
      return;
    }

    if(helper.isEmpty(unitRequestFormScreen.saved_details) || unitRequestFormScreen.saved_details.length == 0){
      helper.warningToast("Isikan detail permintaan, Minimal 1.");
      return;
    }

    setOpenDialogSubmit(false);
    onSubmitPermintaan();
  }

  const handleGetData = () => {
    if(id_permintaan > 0){
      onGetPermintaan(id_permintaan);
    }
  }

  const handleOnDeleteDetail = (idx) => {
    console.log('idx-detail:', idx);
    setIdxDetail(idx);
    setOpenDialog(true);
  }

  const handleDoDeleteDetail = () => {
    onDelDetail(idxDetail);
    setOpenDialog(false);
  }

  const handleGotDetail = (act, params) => {
    onSetDetail();
    console.log('test iki');
    history.push('unit-request-form-detail', params);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'unit-request', title: 'Permintaan Unit', params:{}}}>
          <Flex flex={1} minW="300px">
              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                <LoadingOverlay
                active={unitRequestFormScreen.loading}
                spinner
                text='Loading...'
                >
                <VStack space={7} mt={2} mb={35} mx={3}>
                  {id_permintaan && 
                  <Box mt={5}>
                    <Center>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                        <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                          {unitRequestFormScreen.code_permintaan}
                        </Text>
                      </HStack>
                    </Center>
                  </Box>
                  }
                  <Box p={5} borderRadius="lg" shadow={3} backgroundColor="white">
                      <VStack>
                        <Box mb={2}>
                          <FormControl mb="1" isRequired>
                            <FormControl.Label>Unit</FormControl.Label>
                            {globalVar.list_units && 
                              <MySelect2
                                key="opt-unit"
                                isClearable
                                defaultValue="0"
                                placeholder="Pilih Unit"
                                menuPortalTarget={document.body}
                                getOptionLabel={option => `${option.nama}`}
                                getOptionValue={option => option.id}
                                options={globalVar.list_units}
                                value={globalVar.list_units.filter(({id}) => id === unitRequestFormScreen.id_unit)}
                                onChange={(selectedOption) => onUpdateInput('id_unit',(selectedOption==null) ? 0 : selectedOption.id)}
                              />
                            }
                          </FormControl>
                        </Box>
                        <Box mb={2}>
                          <FormControl mb="1">
                            <FormControl.Label>Catatan</FormControl.Label>
                            {/* <textarea className='myTextArea' value={unitRequestFormScreen.notes} onChange={e => onUpdateInput('notes', e.target.value)}></textarea> */}
                            <TextArea spellCheck={false} value={unitRequestFormScreen.notes} onChange={e => onUpdateInput('notes', e.currentTarget.value)} h={40} placeholder="Catatan jika dibutuhkan" borderRadius="lg" w="100%" />
                          </FormControl>
                        </Box>
                      </VStack>
                  </Box>
                  {unitRequestFormScreen.saved_details.length == 0 && 
                  <Box p={3} borderRadius="lg" shadow={3} backgroundColor="white">
                    <MyAlert status="info" title="Detail kosong, Tambahkan detail permintaan!"/>
                  </Box>
                  }
                  {unitRequestFormScreen.saved_details.length > 0 && 
                  <Box>
                    <Accordion>
                      {unitRequestFormScreen.saved_details.map((raw, i) => (
                        <AccordionItem key={i} header={
                          <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Box>
                              <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                            </Box>
                            <Box flex={1} ml={1} alignItems="start">
                              <Text color="darkText" fontWeight={500} >
                                {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                                Detail Permintaan
                              </Text>
                            </Box>
                            <Box ml={3}>
                              <BadgeStatus status={raw.status ? status_detail[raw.status]: status_detail['10']}/>
                            </Box>
                            {!viewOnly &&
                            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                      </Pressable>;
                              }}>
                                {/* {access.includes('05:VIEW') &&  */}
                                <Menu.Item p={1} onPress={() => handleGotDetail('view', {readOnly: true, idx_detail: i, id_permintaan: unitRequestFormScreen.id_permintaan})}>
                                  <HStack space={2} justifyContent="center" alignItems="center">
                                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                                    <Text color="darkText" fontSize="md">
                                      View
                                    </Text>
                                  </HStack>
                                </Menu.Item>
                                {/* } */}
                                {/* {access.includes('05:EDIT') &&  */}
                                <Menu.Item p={1} onPress={() => handleGotDetail('edit', {readOnly: false, idx_detail: i, id_permintaan: unitRequestFormScreen.id_permintaan})}>
                                  <HStack space={2} justifyContent="center" alignItems="center">
                                    <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                                    <Text color="darkText" fontSize="md">
                                      Update Permintaan
                                    </Text>
                                  </HStack>
                                </Menu.Item>
                                {/* } */}
                                <Menu.Item p={1} onPress={() => handleOnDeleteDetail(i)}>
                                  <HStack space={2} justifyContent="center" alignItems="center">
                                    <Icon as={<Box><MdDelete/></Box>} size="md" color="info.700" />
                                    <Text color="darkText" fontSize="md">
                                      Hapus Permintaan
                                    </Text>
                                  </HStack>
                                </Menu.Item>
                              </Menu>
                            </Box>
                            }
                          </Stack>
                        </Box>
                        } initialEntered>
                          <Flex m={3}>
                            <ImageSlider key={i} images={raw.attachment}/>
                          </Flex>
                          <VStack backgroundColor="white" p={3}>
                            <FormControl mb="1">
                              <FormControl.Label>Jenis Limbah</FormControl.Label>
                              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                                <Text textAlign="left">{raw.nama_limbah}</Text>
                              </Box>
                            </FormControl>
                            <FormControl mb="1">
                              <FormControl.Label>Volume (L)</FormControl.Label>
                              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                                <Text textAlign="right">{raw.volume}</Text>
                              </Box>
                            </FormControl>
                            <FormControl mb="1">
                              <FormControl.Label>Berat (kg)</FormControl.Label>
                              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                                <Text textAlign="right">{raw.berat}</Text>
                              </Box>
                            </FormControl>
                            <FormControl mb="1">
                              <FormControl.Label>Noted</FormControl.Label>
                              <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                                <Text>{raw.note}</Text>
                              </Box>
                            </FormControl>
                          </VStack>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                  }
                  {!viewOnly &&
                  <HStack justifyContent="space-around" mb="32">
                      <Button w="1/3" isLoading={unitRequestFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => setOpenDialogSubmit(true)}>Kirim</Button>
                      <Button variant="link" onPress={() =>  history.replace('unit-request')} colorScheme="primary">Cancel</Button>
                  </HStack>
                  }
                </VStack>
                </LoadingOverlay>
              </ScrollView>
          </Flex>

          <MyAlertDialog 
            alertTitle="Detail Permintaan" 
            alertDesc={`Apakah anda ingin menghapus permintaan ini ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Hapus"
            colorScheme="danger"
            alertIsOpen={isOpenDialog}
            alertOnClose={() => setOpenDialog(false)}
            alertOnConfirm={() => handleDoDeleteDetail()}
          />

          <MyAlertDialog 
            alertTitle="Submit Permintaan" 
            alertDesc={`Apakah data sudah benar ?`}
            alertNoLabel="Tidak"
            alertYesLabel="Kirim"
            colorScheme="success"
            alertIsOpen={isOpenDialogSubmit}
            alertOnClose={() => setOpenDialogSubmit(false)}
            alertOnConfirm={() => handleSubmit()}
          />

          {(screenAccess.includes("2:ADD") && !viewOnly) &&
            <Fab onPress={() => handleGotDetail('add', {readOnly: false, id_permintaan: unitRequestFormScreen.id_permintaan})} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} size="lg" />} />
          }
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  unitRequestFormScreen: makeSelectUnitRequestFormScreen(),
  globalVar: makeSelectGlobalVar()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onDelDetail: idx => dispatch(delSavedDetail(idx)),
    onSubmitPermintaan: () => dispatch(submitPermintaan()),
    onGetPermintaan: (id_permintaan) => dispatch(getPermintaan(id_permintaan)),
    onSetDetail: () => dispatch(setSavedDetail()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(UnitRequestFormScreen);