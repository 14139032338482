import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectWasteTransferFormDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView, RefreshControl, TouchableWithoutFeedback, PermissionsAndroid } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Image,
  Pressable,
  Divider,
  Checkbox,
  FormControl,
  TextArea,
  Select, 
  Flex,
  CheckIcon,
  ScrollView,
  Modal, 
  Button,
  Input} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {MdAddAPhoto,MdDelete } from 'react-icons/md';
import {FaCheckCircle } from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getDetail, getDetailTodo, submitPreventif, submitTransfer, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import moment from 'moment';
import makeSelectGlobalVar from '../../util/selector';
import MySelect2 from '../../components/MySelect2';
import LoadingOverlay from 'react-loading-overlay';

export function WasteTransferFormDetailScreen({
  dispatch,
  location,
  navigation,
  globalVar,
  wasteTransferFormDetailScreen,
  onUpdateInput,
  onGetDetail,
  onAddImage,
  onDelImage,
  onSubmitTransfer,
}){
  const[userData, setUserData] = useState();
  const [kerusakan, setKerusakan] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const {id_detail, id_transfer, id_approval, approval_code} = location.state;
  const readOnly = false;

  useEffect(() => {
    // reset params
    dispatch(defaultAction());
    console.log('first', id_detail, id_transfer, id_approval, approval_code);
    onUpdateInput('id_transfer', id_transfer);
    onUpdateInput('id_approval', id_approval);
    onUpdateInput('id_detail', id_detail);
    onUpdateInput('locationState', {...location.state});
    onGetDetail(id_transfer, id_approval, approval_code);
  },[]);

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(wasteTransferFormDetailScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    handleChangeLimbah(wasteTransferFormDetailScreen.id_jenis_limbah);

    if(helper.isEmpty(wasteTransferFormDetailScreen.description) || wasteTransferFormDetailScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi, Minimal 10 karakter.");
      return;
    }
    // if(wasteTransferFormDetailScreen.assets.length == 0){
    //   helper.warningToast("Bukti foto diperlukan min 1 foto.");
    //   return;
    // }

    console.log('desc:',wasteTransferFormDetailScreen.description.length);
    onSubmitTransfer();
    // setSuccess(true);
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  const handleChangeLimbah =  (jns_limbah_id) => {
    const listTipeLimbah = globalVar.list_waste_types;
    const idx = listTipeLimbah.findIndex(
      result => result.id === jns_limbah_id,
    );

    if(idx >= 0){
      const rowTypeLimbah = listTipeLimbah[idx];
      onUpdateInput('id_jenis_limbah', rowTypeLimbah.id);
      onUpdateInput('jenis_limbah', rowTypeLimbah);
    }
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'waste-transfer-form', title: 'Transfer Limbah', params:{...location.state}}}>
          <Flex flex={1}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <LoadingOverlay
                active={wasteTransferFormDetailScreen.loading}
                spinner
                text='Loading...'
                >
              <VStack space={7} mt={2} mb={35} mx={3}>
                {wasteTransferFormDetailScreen.code && 
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {wasteTransferFormDetailScreen.code ? wasteTransferFormDetailScreen.code : ''}
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                }
                
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                    <VStack>
                      <Box mb={2}>
                      <FormControl mb="1" isRequired>
                        <FormControl.Label>Jenis Limbah</FormControl.Label>
                        {globalVar.list_waste_types && 
                            <MySelect2
                              key="opt-unit"
                              isClearable
                              defaultValue="0"
                              placeholder="Pilih Jenis Limbah"
                              menuPortalTarget={document.body}
                              getOptionLabel={option => `${option.nama}`}
                              getOptionValue={option => option.id}
                              options={globalVar.list_waste_types}
                              value={globalVar.list_waste_types.filter(({id}) => id === wasteTransferFormDetailScreen.id_jenis_limbah)}
                              onChange={(selectedOption) => handleChangeLimbah((selectedOption==null) ? 0 : selectedOption.id)}
                            />
                          }
                          </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Volume (L)</FormControl.Label>
                          <Input keyboardType='numeric' borderRadius="lg" value={wasteTransferFormDetailScreen.volume} onChange={evt => onUpdateInput('volume', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Berat (kg)</FormControl.Label>
                          <Input keyboardType='numeric' borderRadius="lg" value={wasteTransferFormDetailScreen.berat} onChange={evt => onUpdateInput('berat', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>No Kendaraan</FormControl.Label>
                          <Input borderRadius="lg" value={wasteTransferFormDetailScreen.no_kendaraan} onChange={evt => onUpdateInput('no_kendaraan', evt.currentTarget.value)} />
                        </FormControl>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Deskripsi</FormControl.Label>
                          <TextArea h={40} placeholder="Deskripsi" value={wasteTransferFormDetailScreen.description} onChangeText={ txt => onUpdateInput('description', txt)} borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
               
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Upload Foto</Text>
                    </Flex>
                    {!readOnly &&
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack space={3}>
                  <Box justifyContent="center" alignItems="center" p={2} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                    <Files
                      className='files-dropzone'
                      onChange={handleFileChange}
                      onError={handleFileError}
                      accepts={['image/png', 'image/jpg','image/jpeg']}
                      maxFileSize={(10000000 * 20)} // 20 MB
                      minFileSize={0}
                      clickable>
                      Drop files here or click to upload
                    </Files>
                  </Box>
                    {wasteTransferFormDetailScreen.assets.map((rowimage, imgx) => {
                      return (
                        <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                              {/* <Text fontSize="xs" color="muted.400">28-10-2022 09:40</Text> */}
                            </Flex>
                            {!readOnly &&
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box>
                {(!wasteTransferFormDetailScreen.isSuccess && !readOnly) &&
                <Box>
                  <Button isLoading={wasteTransferFormDetailScreen.loading_submit} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()} shadow={3}>Simpan</Button>
                </Box>
                }
              </VStack>
              </LoadingOverlay>
            </ScrollView>
          </Flex>
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  wasteTransferFormDetailScreen: makeSelectWasteTransferFormDetailScreen(),
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetDetail: (id_transfer, id_approval, approval_code) => dispatch(getDetail(id_transfer, id_approval, approval_code)),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitTransfer: () => dispatch(submitTransfer()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(WasteTransferFormDetailScreen);