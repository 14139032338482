import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectUnitAnnnounceFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaCalendarAlt, FaCheckCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getPelaporan, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';

export function UnitAnnnounceFormScreen({
  dispatch,
  location,
  navigation,
  unitAnnnounceFormScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitPelaporan,
  onGetPelaporan
}){
  const {id_branch, id_item, id_aset, dtGedung, nm_aset, id_pelaporan, origin_screen, viewOnly} = {};
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    // reset params
    // dispatch(defaultAction());

    // onUpdateInput('id_pelaporan', id_pelaporan);
    // onUpdateInput('nm_aset', nm_aset);
    // onUpdateInput('id_item', id_item);
    // onUpdateInput('id_aset', id_aset);
    // if(!helper.isEmpty(dtGedung)){
    //   onUpdateInput('nm_ruangan', dtGedung.nm_ruangan);
    // }
    // if(id_pelaporan > 0){
    //   onGetPelaporan(id_pelaporan);
    // }
    // console.log("id_l", id_pelaporan);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(unitAnnnounceFormScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(unitAnnnounceFormScreen.description) || unitAnnnounceFormScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    if(unitAnnnounceFormScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }

    console.log('desc:',unitAnnnounceFormScreen.description.length);

    onSubmitPelaporan(id_branch, origin_screen);
  }

  const handleGetData = () => {
    if(id_pelaporan > 0){
      onGetPelaporan(id_pelaporan);
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'unit-announce', title: 'Pengumuman', params:{id_branch: id_branch, id_item: id_item, new_data: true}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <VStack space={7} mt={2} mb={35} mx={3}>
                <Box mt={5}>
                  <Center>
                    <HStack space={2} justifyContent="center" alignItems="center">
                      <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                      <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                        {/* {unitAnnnounceFormScreen.nm_ruangan} */}
                        UMUM/20240301/01/203123132
                      </Text>
                    </HStack>
                  </Center>
                </Box>
                <Box p={5} borderRadius="lg" shadow={3} backgroundColor="white">
                    <VStack>
                      <Box mb={2}>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Unit</FormControl.Label>
                          <Select 
                            variant='filled' 
                            borderColor="primary.900" 
                            minWidth="200" 
                            accessibilityLabel="Pilih Unit" 
                            placeholder="Pilih Unit" 
                            _selectedItem={{endIcon: <CheckIcon size="5"/>}}
                            // selectedValue={postAnnounceScreen.filter_status}
                            // onValueChange={itemValue => onUpdateInput('filter_status', itemValue)} 
                            my={2}>
                              <Select.Item label="Fakultass Kedokteran" value="0" />
                              <Select.Item label="Fakultass Komputer" value="10" />
                              <Select.Item label="Fakultass Komunikasi" value="20" />
                          </Select>
                        </FormControl>
                      </Box>
                      <Box mb={2}>
                        <FormControl mb="1">
                          <FormControl.Label>Catatan</FormControl.Label>
                          <TextArea value={unitAnnnounceFormScreen.notes} onChangeText={txt => onUpdateInput('notes', txt)} h={40} placeholder="Catatan jika dibutuhkan" borderRadius="lg" w="100%" />
                        </FormControl>
                      </Box>
                    </VStack>
                </Box>
                {/* <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor}>
                  <HStack mb={4}>
                    <Flex flex={1}>
                      <Text fontSize="md">Foto Kerusakan</Text>
                    </Flex>
                    {!viewOnly && 
                    <Box width="120px">
                      <Button onPress={() => handleCameraOpen()} leftIcon={<Icon as={<Box><MdAddAPhoto/></Box>} size="sm" />}>
                        Ambil Foto
                      </Button>
                    </Box>
                    }
                  </HStack>
                  <VStack space={3}>
                    <Box>
                    <Box justifyContent="center" alignItems="center" mt={2} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1} borderStyle="dashed">
                      <Files
                        className='files-dropzone'
                        onChange={handleFileChange}
                        onError={handleFileError}
                        accepts={['image/png', 'image/jpg','image/jpeg']}
                        maxFileSize={(10000000 * 20)} // 20 MB
                        minFileSize={0}
                        clickable>
                        Drop files here or click to upload
                      </Files>
                    </Box>
                    </Box>
                    {unitAnnnounceFormScreen.assets.map((rowimage, imgx) => {
                      return (
                        <Box key={`img-${imgx}`} p={2} flex={1} borderColor="muted.400" borderRadius="lg" borderWidth={1}>
                          <Flex mb={2} justifyContent="center" alignItems="center">
                          <AspectRatio
                          ratio={{
                            base: 3 / 4,
                          }} height={{
                            base: 350,
                          }}>
                            <Image alt={`photo${imgx}`} resizeMode="contain" source={{uri: rowimage.path}}/>
                          </AspectRatio>
                          </Flex>
                          <HStack justifyContent="center" alignItems="center">
                            <Flex flex={1} flexDirection="column">
                              <Text fontSize="sm" fontWeight="bold">Foto {`${imgx+1}`}</Text>
                            </Flex>
                            {!viewOnly &&
                            <Box>
                              <Pressable onPress={() => onDelImage(imgx)}>
                                <Icon as={<Box><MdDelete/></Box>} color="red.500" size="lg"></Icon>
                              </Pressable>
                            </Box>
                            }
                          </HStack>
                        </Box>
                        // <View>
                        //   <Image alt={`photo${imgx}`} style={styles.image} source={{uri: rowimage}}/>
                        // </View>
                      );
                    })
                    }
                  </VStack>
                </Box> */}
                <Box>
                  <Accordion>
                  {[...Array(3)].map((x, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                            Detail Permintaan
                          </Text>
                        </Box>
                        <Box ml={3}>
                          <BadgeStatus status="Created"/>
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            {/* {access.includes('05:VIEW') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  View
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            {/* {access.includes('05:EDIT') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Update Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDelete/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Hapus Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    } initialEntered>
                      <Flex m={3}>
                        <ImageSlider key={i} images={imageList}/>
                      </Flex>
                      <VStack backgroundColor="white" p={3}>
                        <FormControl mb="1">
                          <FormControl.Label>Jenis Limbah</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">Cair</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Volume (L)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">60</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Berat (kg)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">80</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s</Text>
                          </Box>
                        </FormControl>
                      </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box>
                {/* {!viewOnly && */}
                <HStack justifyContent="space-around" mb="32">
                    <Button w="1/3" isLoading={unitAnnnounceFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()}>Kirim</Button>
                    <Button variant="link" colorScheme="primary">Cancel</Button>
                </HStack>
                {/* } */}
              </VStack>
            </ScrollView>
          </Flex>
          <Modal isOpen={openModalCamera} onClose={() => setOpenModalCamera(false)}>
            <Modal.Content maxWidth="500px">
              <Modal.Header>Ambil Gambar</Modal.Header>
              <Modal.Body>
              {openCamera == true && 
              <Camera
                onTakePhoto = { (dataUri) => { handleChoosePhoto(dataUri); } }
                onTakePhotoAnimationDone = { (dataUri) => { handleTakePhotoAnimationDone(dataUri); } }
                onCameraError = { (error) => { handleCameraError(error); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                idealResolution = {{width: 640, height: 800}}
                imageType = {IMAGE_TYPES.JPG}
                imageCompression = {0.70}
                isMaxResolution = {true}
                isImageMirror = {false}
                isSilentMode = {false}
                isDisplayStartCameraError = {true}
                isFullscreen = {false}
                sizeFactor = {1}
                onCameraStart = { (stream) => { handleCameraStart(stream); } }
                onCameraStop = { () => { handleCameraStop(); } }
              />
            }
              </Modal.Body>
            </Modal.Content>
          </Modal>
          {/* {screenAccess.includes("05:ADD") &&  */}
          <Fab onPress={() => history.push('unit-announce-form-detail')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} size="lg" />} />
          {/* } */}
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  unitAnnnounceFormScreen: makeSelectUnitAnnnounceFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitPelaporan: (id_branch, origin_screen) => dispatch(submitPelaporan(id_branch, origin_screen)),
    onGetPelaporan: (id_pelaporan) => dispatch(getPelaporan(id_pelaporan)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(UnitAnnnounceFormScreen);