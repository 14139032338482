import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Progress,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert, MdDelete, MdDownload, MdDriveFileMove, MdOutlineWater, MdOutlineWaterDrop } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown, FaCalendarTimes, FaCalendarCheck, FaWrench, FaTruck, FaBuilding, FaRegBuilding } from 'react-icons/fa';
  import moment from "moment";
  import BadgeStatus from "./BadgeStatus";
  import helper from "../util/helper";
  import history from "../util/history";

const CardWasteUnitList = (props) => {
  const {data, access} = props;
  return (
    <Box width="100%" backgroundColor={commonStyle.baseColor} shadow={3} borderRadius="lg">
      <VStack>
        <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg} borderTopRadius="lg">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
            </Box>
            <Box flex={1} ml={1}>
              <Text color="darkText" fontWeight={500} >
                {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                17 Agustus 2024
              </Text>
            </Box>
            {/* <Box ml={3}>
              <BadgeStatus status="Created"/>
            </Box> */}
            <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
              <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                        <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                      </Pressable>;
              }}>
                {/* {access.includes('05:VIEW') &&  */}
                <Menu.Item p={1} onPress={() => history.push('manifest-detail')}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      View
                    </Text>
                  </HStack>
                </Menu.Item>
                {/* } */}
                <Menu.Item p={1} onPress={() => {}}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Download BA
                    </Text>
                  </HStack>
                </Menu.Item>
                <Menu.Item p={1} onPress={() => {}}>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                    <Text color="darkText" fontSize="md">
                      Download Manifest
                    </Text>
                  </HStack>
                </Menu.Item>
              </Menu>
            </Box>
          </Stack>
        </Box>
        <Box p={5}>
          <VStack>
            <Flex flexDirection="row" mb={3} alignItems="center">
              <Box width={8} height={8} borderRadius="lg" backgroundColor="primary.400" justifyContent="center">
                <Center>
                  <Icon color="white" as={<Box><FaBuilding/></Box>} size={5}></Icon>
                </Center>
              </Box>
              <Box ml={2}>
                <Text color="darkText">
                  {/* {data.code_work_order} */}
                  Fakultas Farmasi
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="row" mb={3}>
              <Box>
                <Center>
                  <Avatar size="md" bg="primary.900" mr="1">
                      {/* {helper.getInitials(data.nama_pegawai_report)} */}
                      IS
                  </Avatar>
                </Center>
              </Box>
              <VStack width="90%">
                <Text ml={3} fontSize="lg" color={commonStyle.primaryColor} isTruncated width="80%" fontWeight="bold">
                  {/* {data.nama_pegawai_report} */}
                  dr. Indra Syahputra, Sp.PD
                </Text>
                <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                  {/* {data.hp_pegawai_report} */}
                  081280123232
                </Text>
              </VStack>
            </Flex>
            <HStack flex={1} mb={3} space={2}>
              <Box flex={1}>
                <VStack>
                  <Text color="darkText" bold>Total Berat</Text>
                  <Text color="darkText">
                    {/* {data.forcast_date_finish ? moment(data.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm") : '-'} */}
                    2024 Ton
                  </Text>
                </VStack>
              </Box>
              <Box flex={1}>
                <VStack>
                  <Text color="darkText" bold>Total Volume</Text>
                  <Text color="darkText">
                    {/* {data.forcast_date_finish ? moment(data.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm") : '-'} */}
                    2024 L
                  </Text>
                </VStack>
              </Box>
            </HStack>
            <Box mt={3}>
              <div className="table-responsive">
                <table className="table table-sm">
                <tbody>
                  <tr>
                    <th scope="row">
                      <Center>
                        <Icon color="black" as={<Box><MdOutlineWaterDrop/></Box>} size={5}></Icon>
                      </Center>
                    </th>
                    <td>Non Medis - JK001BD</td>
                    <td>1.000 ton</td>
                    <td>200 l</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Center>
                        <Icon color="black" as={<Box><MdOutlineWaterDrop/></Box>} size={5}></Icon>
                      </Center>
                    </th>
                    <td>Plastik - Plt001HK</td>
                    <td>1.000 ton</td>
                    <td>500 l</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Center>
                        <Icon color="black" as={<Box><MdOutlineWaterDrop/></Box>} size={5}></Icon>
                      </Center>
                    </th>
                    <td>Medis - Mtt001HK</td>
                    <td>100 ton</td>
                    <td>100 L</td>
                  </tr>
                </tbody>
                </table>
              </div>  
            </Box>
          </VStack>
        </Box>
      </VStack>
  </Box>
  );
}

export default memo(CardWasteUnitList);