import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectManifestDetailScreen from './selector';
import reducer from './reducer';
import saga from './saga';

// import {ScrollView,RefreshControl, BackHandler, PermissionsAndroid, StyleSheet, View, Dimensions } from 'react-native';
import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Text,
  Pressable,
  TextArea,
  FormControl,
  Divider,
  Image,
  Modal,
  Flex,
  ScrollView, 
  Button,
  Select,
  CheckIcon,
  Stack,
  Menu,
  Fab} from 'native-base';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import Files from 'react-files';
import 'react-html5-camera-photo/build/css/index.css';
import {FaBuilding, FaCalendarAlt, FaCheckCircle, FaHourglassEnd, FaTimesCircle} from 'react-icons/fa';
import {MdAddAPhoto, MdDelete, MdDownload, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye} from 'react-icons/md';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { addImage, defaultAction, delImage, getPelaporan, submitPelaporan, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';

export function ManifestDetailScreen({
  dispatch,
  location,
  navigation,
  manifestDetailScreen,
  onUpdateInput,
  onAddImage,
  onDelImage,
  onSubmitPelaporan,
  onGetPelaporan
}){
  const {id_branch, id_item, id_aset, dtGedung, nm_aset, id_pelaporan, origin_screen, viewOnly} = {};
  const[userData, setUserData] = useState();
  const [photo, setPhoto] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [openModalCamera, setOpenModalCamera] = useState(false);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    // reset params
    // dispatch(defaultAction());

    // onUpdateInput('id_pelaporan', id_pelaporan);
    // onUpdateInput('nm_aset', nm_aset);
    // onUpdateInput('id_item', id_item);
    // onUpdateInput('id_aset', id_aset);
    // if(!helper.isEmpty(dtGedung)){
    //   onUpdateInput('nm_ruangan', dtGedung.nm_ruangan);
    // }
    // if(id_pelaporan > 0){
    //   onGetPelaporan(id_pelaporan);
    // }
    // console.log("id_l", id_pelaporan);
  },[]);
  

  const handleChoosePhoto = async (data) => {
    setOpenCamera(false);
    console.log("img:", data);
    setTimeout(() => {
      setOpenModalCamera(false);
    }, 300);
    if(manifestDetailScreen.assets.length == 4){
      helper.infoToast("Anda melebihi batas maksimum yakni 4 foto.");
      return;
    }

    try {
      if(!helper.isEmpty(data)) onAddImage(data);
      // const granted = await PermissionsAndroid.request(
      //   PermissionsAndroid.PERMISSIONS.CAMERA,
      //   {
      //     title: "App Camera Permission",
      //     message:"App needs access to your camera",
      //     buttonNeutral: "Ask Me Later",
      //     buttonNegative: "Cancel",
      //     buttonPositive: "OK"
      //   }
      // );
      // if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      //   console.log("Camera permission given");
      //   launchCamera({ 
      //     mediaType: 'photo',
      //     maxWidth: 800,
      //     maxHeight: 800,
      //     includeBase64: true,
      //     cameraType: 'back'
      //   }, (response) => {
      //     console.log(response);
      //     if (response) {
      //       if(!helper.isEmpty(response.assets)) onAddImage(response.assets[0]);
      //     }
      //   });
      // } else {
      //   console.log("Camera permission denied");
      // }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleSubmit = () => {
    if(helper.isEmpty(manifestDetailScreen.description) || manifestDetailScreen.description.length < 10){
      helper.warningToast("Isikan deskripsi kerusakan, Minimal 10 karakter.");
      return;
    }
    if(manifestDetailScreen.assets.length == 0){
      helper.warningToast("Bukti foto diperlukan min 1 foto.");
      return;
    }

    console.log('desc:',manifestDetailScreen.description.length);

    onSubmitPelaporan(id_branch, origin_screen);
  }

  const handleGetData = () => {
    if(id_pelaporan > 0){
      onGetPelaporan(id_pelaporan);
    }
  }

  const handleCameraOpen = () => {
    setOpenModalCamera(true);
    setTimeout(() => {
      setOpenCamera(true);
    }, 300);
  }

  function handleTakePhotoAnimationDone (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError (error) {
    // console.log('handleCameraError', error);
  }

  function handleCameraStart (stream) {
    // console.log('handleCameraStart : ', stream);
  }

  function handleCameraStop () {
    // console.log('handleCameraStop');
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  const handleFileChange = (files) => {
    console.log(files);
    const file = files[0];
    getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        const params = {
          base64: result,
          type: file.type,
          name: file.name,
        }

        onAddImage(params.base64);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFileError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
    helper.errorToast(error.message);
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'manifest', title: 'BA & Manifest', params:{id_branch: id_branch, id_item: id_item, new_data: true}}}>
          <Flex flex={1} minW="300px">
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              <Box mt={5}>
                <Center>
                  <HStack space={2} justifyContent="center" alignItems="center">
                    <Icon as={<Box><FaCheckCircle/></Box>} size="8" color="success.700" />
                    <Text color="darkText" fontWeight="bold" isTruncated maxWidth="90%" fontSize="lg">
                      {/* {unitAnnnounceFormScreen.nm_ruangan} */}
                      Fakultas Farmasi
                    </Text>
                  </HStack>
                </Center>
              </Box>
              <Box p={5} borderRadius="lg" shadow={3} backgroundColor={commonStyle.baseColor} mx={3} mt={7}>
                <VStack>
                  <Center>
                    <Avatar size="lg" bg="primary.900" mb={4}>
                        {/* {helper.getInitials(dtProgress.nama_inventaris)} */}
                        IG
                    </Avatar>
                  </Center>
                  <Center>
                    <Text color="darkText" isTruncated maxWidth="90%" fontSize="md" fontWeight="bold">
                      {/* {dtProgress.nama_inventaris} */}
                      PT Maju Sejahtera
                    </Text>
                  </Center>
                  <Center>
                    <Text color="muted.400" isTruncated maxWidth="90%" fontSize="sm">
                      {/* {dtProgress.code_inventaris} */}
                      Kasie Approval
                    </Text>
                  </Center>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">No BA</Text>
                      <Text color="darkText" bold>
                        {/* {dtProgress.code_work_order} */}
                        TRF/20240301/05/203123132
                      </Text>
                    </VStack>
                  </Box>
                  <Box mt={2}>
                    <VStack>
                      <Text fontSize="xs" color="muted.600">Tanggal Approval</Text>
                      <Text color="darkText" bold>
                        {/* {moment(dtProgress.forcast_date_finish, "YYYY-MM-DD HH:mm:ss").format("DD MMMM YYYY")} */}
                        21 Sep 2024
                      </Text>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
              <VStack space={7} mt={3} mb={35} mx={3}>
                <Box>
                  <Accordion>
                  {[...Array(3)].map((x, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            Detail BA
                          </Text>
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            {/* {access.includes('05:VIEW') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Download BA
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            {/* {access.includes('05:EDIT') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDownload/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Download Manifest
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    } initialEntered>
                      <Flex m={3}>
                        <ImageSlider key={i} images={imageList}/>
                      </Flex>
                      <VStack backgroundColor="white" p={3}>
                      <Box mb={2}>
                        <Text fontWeight="bold">
                          Lantai
                        </Text>
                      </Box>
                      <HStack flex={1} mb={2}>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaCalendarAlt/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">
                              {/* {moment(pelaporan.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                              12 Oktober 2024
                            </Text>
                          </Box>
                        </Stack>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaHourglassEnd/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">
                              {/* {pelaporanHistoryDetailScreen.raw_detail.nm_ruang} */}
                              Progres
                            </Text>
                          </Box>
                        </Stack>
                      </HStack>
                      <HStack flex={1} mb={2}>
                        <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
                          <Box>
                            <Icon color="muted.400" as={<Box><FaBuilding/></Box>} size={3}></Icon>
                          </Box>
                          <Box flex={1} ml={1}>
                            <Text color="muted.400" fontWeight={500} fontSize="xs">
                              {/* {moment(pelaporan.date_create, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                              Fakultas Kedokteran
                            </Text>
                          </Box>
                        </Stack>
                      </HStack>
                        <FormControl mb="1">
                          <FormControl.Label>Jenis Limbah</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">Cair</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Volume (L)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">60</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Berat (kg)</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="right">80</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="3">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s</Text>
                          </Box>
                        </FormControl>
                        </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box>
              </VStack>
            </ScrollView>
          </Flex>
        </ContainerPage>
      );
    
}

const mapStateToProps = createStructuredSelector({
  manifestDetailScreen: makeSelectManifestDetailScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onAddImage: asset => dispatch(addImage(asset)),
    onDelImage: idx => dispatch(delImage(idx)),
    onSubmitPelaporan: (id_branch, origin_screen) => dispatch(submitPelaporan(id_branch, origin_screen)),
    onGetPelaporan: (id_pelaporan) => dispatch(getPelaporan(id_pelaporan)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(ManifestDetailScreen);