/*
 *
 * LoginScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    username: 'admin_sarpras@gmail.com',//pradnyan_teknik@yopmail.com,tes_20210912_01@gmail.com
    password: 'Admin1122',//Root8707402
    user: null,
    isValid: false,
    isChecked: false,
    user_privileges: '',
    user_notif: null,
};

const LoginScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        // console.log(action, draft);
        break;
      case constants.SET_USER_NOTIF:
        draft.user_notif = action.notif;
        break;
      case constants.DO_LOGIN:
        draft.loading = true;
        break;
      case constants.DO_LOGIN_SUCCESS:
        draft.loading = false;
        draft.error = 0 ;
        draft.error_msg= '';
        draft.isValid = true;
        draft.isChecked = true;
        draft.user = action.result;
        break;
      case constants.DO_LOGIN_FAIL:
        draft.loading = false;
        draft.isValid = false;
        draft.isChecked = true;
        draft.error = 1 ;
        draft.error_msg= 'Maaf, Kombinasi username atau password masih belum sesuai.';
        break;
      case constants.GET_USER_PRIVILEGES:
        draft.loading = true;
        break;
      case constants.GET_USER_PRIVILEGES_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.isValid = false;
        draft.isChecked = true;
        draft.error = 1 ;
        draft.error_msg= 'Maaf, Gagal mengambil data hak akses.';
        break;
      case constants.GET_USER_PRIVILEGES_LOADING:
        draft.loading = true;
        break;
      case constants.GET_USER_PRIVILEGES_SUCCESS:
        draft.user_privileges = action.results;
        draft.loading = false;
        draft.isValid = true;
        draft.isChecked = true;
        break;
    }
  });

export default LoginScreenReducer;
