import React, { Component, useState, useEffect, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPostAnnounceScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  VStack, 
  Box, 
  Center,
  Stack,
  Badge,
  Input,
  Image,
  IconButton,
  Select,
  CheckIcon,
  Fab,
  Text,
  ScrollView, 
  Flex, 
  Actionsheet,
  FormControl,
  Button,
  WarningOutlineIcon} from 'native-base';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import DatePicker from "react-datepicker";
import {MdCalendarToday} from 'react-icons/md';
import {AiOutlinePlus} from 'react-icons/ai';
import { ContainerPage } from '../../components/ContainerPage';
import CardListPreloader from '../../components/CardListPreloader';
import MyAlert from '../../components/MyAlert';
import CardPostAnnounceList from '../../components/CardPostAnnouncetList';
import { commonStyle } from '../../styles';
import { defaultAction, getMorePostAnnounce, getMoreWOReport, getPostAnnounce, getWorkOrderReport, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import history from '../../util/history';
import RangeDateCustom from '../../components/RangeDateCustom';

export function PostAnnounceScreen({
  dispatch,
  navigation,
  postAnnounceScreen,
  onUpdateInput,
  onGetResult,
  onGetMoreResult
}){
  const [startDt, setStartDt] = useState(new Date());
  const [endDt, setEndDt] = useState(new Date());
  const [gedung, setGedung] = useState();
  const [screenAccess, setScreenAccess] = useState([]);
  const [isOpen, setOnOpen] = useState(false);
  const scrollableRef = useRef(null);

  useEffect(() => {
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
    onUpdateInput('page', 1);
    onGetResult();
  },[]);

  const onChangeStartDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("start_dt", currentDate);
    onGetResult();
  };
  const onChangeEndDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("end_dt", currentDate);
    onGetResult();
  };

  const handleGetData = () => {
    onUpdateInput('page', 1);
    onGetResult()
  }

  const renderItem = ({item}) => {
    return (
      <Box px={3} mb={5}>
        <CardPostAnnounceList data={item} access={screenAccess} navigation={navigation} />
      </Box>
    );
  }

  const getItem = (_data, index) => ({..._data[index]});
  
  const getItemCount = _data => postAnnounceScreen.results.length;

  const getItemLayout = (data, index) => ({length: 320, offset: 320 * index, index});

  const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(postAnnounceScreen.start_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(postAnnounceScreen.end_dt).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputLeftElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} ml="2" color="muted.400" />} 
      />
  ));

  const handleLoadMore = () => {
    console.log('load more:', postAnnounceScreen.page);
    if(postAnnounceScreen.page < postAnnounceScreen.total_page){
      onUpdateInput('page', postAnnounceScreen.page + 1);
    }
    onGetMoreResult();
  }

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'home', title: 'Kirim Pengumuman', enableAct: true, onPressAction : () => setOnOpen(true)}}>
          <Flex flex={1}>
            <ScrollView ref={scrollableRef} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
              {
                postAnnounceScreen.loading && <VStack space={7} px={3} mt={2} mb={35}>
                  {[...Array(3)].map((x, i) => (
                    <CardListPreloader key={i}/>
                  ))}
                </VStack>
              }
              {
                !postAnnounceScreen.loading && <>
                  {postAnnounceScreen.results.length == 0 && <MyAlert status="info" title="Maaf, Hasil belum ditemukan."/>}
                  {postAnnounceScreen.results?.data?.length > 0 &&
                    <InfiniteScroll
                    key={scrollableRef.current}
                      dataLength={postAnnounceScreen.results?.data?.length}
                      next={handleLoadMore}
                      hasMore={(postAnnounceScreen.page < postAnnounceScreen.total_page)}
                      loader={<p style={{textAlign:'center'}}>Memuat Lebih...</p>}
                      scrollableTarget={scrollableRef.current}
                    >
                      <VStack space={7} px={3} mt={2} mb={35}>
                        {postAnnounceScreen.results.data.map((row, i) => (
                          <CardPostAnnounceList key={i} data={row} access={screenAccess} navigation={navigation} />
                        ))}
                      </VStack>
                    </InfiniteScroll>
                  }
                </>
              }
              {/* <VStack space={7} px={3} mt={2} mb={35}>
                {[...Array(3)].map((x, i) => (
                  <CardPostAnnounceList key={i}/>
                ))}
              </VStack> */}
            </ScrollView>
          </Flex>
          <Actionsheet
            isOpen={isOpen} onClose={() => setOnOpen(false)}>
            <Actionsheet.Content>
              <Box w="100%" px={4} justifyContent="center">
                <Text fontSize="16" fontWeight="bold" color="gray.500" _dark={{
                color: "gray.300"
              }}>
                  Filter
                </Text>
              </Box>
              <VStack w="100%" px={4}>
                <Box>
                  <FormControl isInvalid={postAnnounceScreen.q.length > 0 && postAnnounceScreen.q.length < 3} mb="1">
                    <FormControl.Label>Pencarian</FormControl.Label>
                    <Input placeholder="Ketik disini..." w="100%" value={postAnnounceScreen.q} onChangeText={txt => onUpdateInput('q', txt)} />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                      3 characters minimum.
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Tanggal</FormControl.Label>
                    <RangeDateCustom startDt={postAnnounceScreen.start_dt} endDt={postAnnounceScreen.end_dt} changeStartDt={(date) => onUpdateInput('start_dt', date)} changeEndDt={(date) => onUpdateInput('end_dt', date)} />
                  </FormControl>
                  <FormControl mb="1">
                    <FormControl.Label>Status</FormControl.Label>
                    <Select 
                      variant='filled' 
                      borderColor="primary.900" 
                      minWidth="200" 
                      accessibilityLabel="Pilih Status" 
                      placeholder="Pilih Status" 
                      _selectedItem={{endIcon: <CheckIcon size="5"/>}}
                      selectedValue={postAnnounceScreen.filter_status}
                      onValueChange={itemValue => onUpdateInput('filter_status', itemValue)} 
                      my={2}>
                        <Select.Item label="All" value="0" />
                        <Select.Item label="Created" value="10" />
                        <Select.Item label="Batal" value="20" />
                        <Select.Item label="Approve" value="30" />
                        <Select.Item label="Done" value="40" />
                      </Select>
                  </FormControl>
                  </Box>
                  <Box my={1}>
                    <Button isLoading={postAnnounceScreen.loading} onPress={() => handleGetData()} spinnerPlacement="end" isLoadingText="Loading..." shadow={3} colorScheme="primary">Terapkan</Button>
                  </Box>
              </VStack>
            </Actionsheet.Content>
          </Actionsheet>
          {screenAccess.includes("8:ADD") && 
          <Fab onPress={() => history.push('post-announce-form',{new_data: true})} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} size="lg" />} />
          }
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  postAnnounceScreen: makeSelectPostAnnounceScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetResult: () => dispatch(getPostAnnounce()),
    onGetMoreResult: () => dispatch(getMorePostAnnounce()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PostAnnounceScreen);