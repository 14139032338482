import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import api from './api';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function updateInput({ name, value }) {
  return {
    type: constants.UPDATE_INPUT,
    name,
    value
  };
}

export function getWasteType() {
  return {
    type: constants.GET_WASTE_TYPE
  };
}

export function getWasteTypeLoading() {
  return {
    type: constants.GET_WASTE_TYPE_LOADING
  };
}

export function getWasteTypeFail(error) {
  return {
    type: constants.GET_WASTE_TYPE_FAIL,
    error
  };
}

export function getWasteTypeSuccess(results) {
  return {
    type: constants.GET_WASTE_TYPE_SUCCESS,
    results
  };
}

export function getListUnit() {
  return {
    type: constants.GET_LIST_UNIT,
  };
}

export function getListUnitLoading() {
  return {
    type: constants.GET_LIST_UNIT_LOADING
  };
}

export function getListUnitFail(error) {
  return {
    type: constants.GET_LIST_UNIT_FAIL,
    error
  };
}

export function getListUnitSuccess(results) {
  return {
    type: constants.GET_LIST_UNIT_SUCCESS,
    results
  };
}

export function getPegawai() {
  return {
    type: constants.GET_PEGAWAI
  };
}

export function getPegawaiLoading() {
  return {
    type: constants.GET_PEGAWAI_LOADING
  };
}

export function getPegawaiFail(error) {
  return {
    type: constants.GET_PEGAWAI_FAIL,
    error
  };
}

export function getPegawaiSuccess(results) {
  return {
    type: constants.GET_PEGAWAI_SUCCESS,
    results
  };
}

export function getVendor() {
  return {
    type: constants.GET_VENDOR
  };
}

export function getVendorLoading() {
  return {
    type: constants.GET_VENDOR_LOADING
  };
}

export function getVendorFail(error) {
  return {
    type: constants.GET_VENDOR_FAIL,
    error
  };
}

export function getVendorSuccess(results) {
  return {
    type: constants.GET_VENDOR_SUCCESS,
    results
  };
}