/*
 *
 * WelcomeScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_waste: false,
    loading_all_unit: false,
    loading_pegawai: false,
    loading_vendor: false,
    list_waste_types: [],
    list_units: [],
    list_pegawai: [],
    list_vendor: [],
};

/* eslint-disable default-case, no-param-reassign */
const GlobalVarReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
        break;
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.GET_WASTE_TYPE:
        draft.loading_waste = true;
        break;
      case constants.GET_WASTE_TYPE_FAIL:
        draft.error = true;
        draft.loading_waste = false;
        break;
      case constants.GET_WASTE_TYPE_LOADING:
        draft.loading_waste = true;
        break;
      case constants.GET_WASTE_TYPE_SUCCESS:
        draft.list_waste_types = action.results;
        draft.loading_waste = false;
        break;
      case constants.GET_LIST_UNIT:
        draft.list_units = [];
        draft.loading_all_unit = true;
      break;
      case constants.GET_LIST_UNIT_FAIL:
        draft.error = true;
        draft.loading_all_unit = false;
        break;
      case constants.GET_LIST_UNIT_LOADING:
        draft.loading_all_unit = true;
        break;
      case constants.GET_LIST_UNIT_SUCCESS:
        draft.list_units = action.results;
        draft.loading_all_unit = false;
        break;
      case constants.GET_PEGAWAI:
        draft.loading_pegawai = true;
      break;
      case constants.GET_PEGAWAI_FAIL:
        draft.error = true;
        draft.loading_pegawai = false;
        break;
      case constants.GET_PEGAWAI_LOADING:
        draft.loading_pegawai = true;
        break;
      case constants.GET_PEGAWAI_SUCCESS:
        draft.list_pegawai = action.results;
        draft.loading_pegawai = false;
        break;
      case constants.GET_VENDOR:
        draft.loading_vendor = true;
      break;
      case constants.GET_VENDOR_FAIL:
        draft.error = true;
        draft.loading_vendor = false;
        break;
      case constants.GET_VENDOR_LOADING:
        draft.loading_vendor = true;
        break;
      case constants.GET_VENDOR_SUCCESS:
        draft.list_vendor = action.results;
        draft.loading_vendor = false;
        break;
    }
  });

export default GlobalVarReducer;
