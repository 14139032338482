/*
 *
 * UnitRequestFormScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    notes: '',
    id_permintaan: 0,
    id_aset: 0,
    nm_aset: "",
    nm_ruangan: "",
    id_branch: 0,
    id_item: 0,
    assets:[],
    unit_request: null,
    code_permintaan: '',
    id_unit: 0,
    saved_details: []
};

/* eslint-disable default-case, no-param-reassign */
const UnitRequestFormScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      console.log("reset state");
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      case constants.SUBMIT_PERMINTAAN:
        draft.loading = true;
        break;
      case constants.SUBMIT_PERMINTAAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_PERMINTAAN_SUCCESS:
        // draft.id_permintaan = action.results.id_permintaan;
        draft.loading = false;
        draft.error = false;
        draft.description = "";
        draft.notes = "";
        draft.saved_details = [];
        break;
      case constants.ADD_IMAGE:{
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image});
        draft.assets = cur_arr;
      }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.DEL_SAVED_DETAIL:
        draft.saved_details = [...draft.saved_details.slice(0, action.idx), ...draft.saved_details.slice(action.idx + 1)];
        break;
      case constants.GET_PERMINTAAN:
        draft.loading = true;
        break;
      case constants.GET_PERMINTAAN_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.error_msg= 'Maaf, Gagal mengambil data permintaan unit.';
        draft.results = [];
        break;
      case constants.GET_PERMINTAAN_SUCCESS:{
        draft.unit_request = action.results;
        draft.code_permintaan = action.results?.code_permintaan;
        draft.id_unit = action.results?.id_unit;
        draft.notes = action.results?.noted;
        draft.loading = false;

        let cur_saved_detail = [];
        action.results?.permintaan_unit_detail.map((raw, i) => {
          cur_saved_detail.push({
            id_jenis_limbah: raw.id_jenis_limbah,
            code_limbah: raw.code_limbah,
            nama_limbah: raw.ms_jns_limbah?.nama,
            jenis_limbah: raw.ms_jns_limbah,
            volume: raw.volume,
            berat: raw.berat,
            attachment: raw.permintaan_unit_attachment,
            note: raw.note,
            status: raw.status
          });
        });
        draft.saved_details = cur_saved_detail;
        
        } 
        break;
    }
  });

export default UnitRequestFormScreenReducer;
