export const DEFAULT_ACTION = 'screen/ManifestDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ManifestDetailScreen/UPDATE_INPUT';

export const SUBMIT_PELAPORAN = 'screen/ManifestDetailScreen/SUBMIT_PELAPORAN';
export const SUBMIT_PELAPORAN_LOADING = 'screen/ManifestDetailScreen/SUBMIT_PELAPORAN_LOADING';
export const SUBMIT_PELAPORAN_SUCCESS = 'screen/ManifestDetailScreen/SUBMIT_PELAPORAN_SUCCESS';
export const SUBMIT_PELAPORAN_FAIL = 'screen/ManifestDetailScreen/SUBMIT_PELAPORAN_FAIL';

export const ADD_IMAGE = 'screen/ManifestDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/ManifestDetailScreen/DEL_IMAGE';

export const GET_PELAPORAN = 'screen/ManifestDetailScreen/GET_PELAPORAN';
export const GET_PELAPORAN_LOADING = 'screen/ManifestDetailScreen/GET_PELAPORAN_LOADING';
export const GET_PELAPORAN_SUCCESS = 'screen/ManifestDetailScreen/GET_PELAPORAN_SUCCESS';
export const GET_PELAPORAN_FAIL = 'screen/ManifestDetailScreen/GET_PELAPORAN_FAIL';