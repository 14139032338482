export const DEFAULT_ACTION = 'screen/ManifestScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/ManifestScreen/UPDATE_INPUT';

export const GET_WORK_ORDER_REPORT = 'screen/ManifestScreen/GET_WORK_ORDER_REPORT';
export const GET_WORK_ORDER_REPORT_LOADING = 'screen/ManifestScreen/GET_WORK_ORDER_REPORT_LOADING';
export const GET_WORK_ORDER_REPORT_SUCCESS = 'screen/ManifestScreen/GET_WORK_ORDER_REPORT_SUCCESS';
export const GET_WORK_ORDER_REPORT_FAIL = 'screen/ManifestScreen/GET_WORK_ORDER_REPORT_FAIL';

export const GET_MORE_WO_REPORT = 'screen/ManifestScreen/GET_MORE_WO_REPORT';
export const GET_MORE_WO_REPORT_LOADING = 'screen/ManifestScreen/GET_MORE_WO_REPORT_LOADING';
export const GET_MORE_WO_REPORT_SUCCESS = 'screen/ManifestScreen/GET_MORE_WO_REPORT_SUCCESS';
export const GET_MORE_WO_REPORT_FAIL = 'screen/ManifestScreen/GET_MORE_WO_REPORT_FAIL';