import React, {memo} from "react";
import { commonStyle } from "../styles";
import {
    Icon,
    HStack, 
    Avatar, 
    Heading, 
    VStack, 
    Box, 
    Center,
    Stack,
    Badge,
    Input,
    Image,
    IconButton,
    Pressable,
    Divider,
    HamburgerIcon,
    Menu,
    Text, 
    Flex } from 'native-base';
  import {MdRemoveRedEye, MdModeEdit, MdHistory, MdMoreVert, MdAddTask } from 'react-icons/md';
  import {FaCalendarAlt, FaEnvelope, FaAngleDown, FaCalendarTimes, FaCalendarCheck, FaWrench, FaHammer, FaRegBuilding, FaHouseUser, FaRegClock, FaRegUser, FaRegEdit } from 'react-icons/fa';
  import moment from "moment";
  import helper from "../util/helper";
  import BadgeStatus from "./BadgeStatus";
  import history from "../util/history";

const CardApprovalList = (props) => {
  const {data, access, cardType, actionApprove} = props;
  return (
    <Box width="100%" backgroundColor="white" shadow={3} borderRadius="lg">
        <VStack>
          <Box px={5} py={3} backgroundColor={cardType == 1 ? `#E4F6F4` : cardType == 2 ? `#FEE7DE`:`#ECFDF5`} borderTopRadius="lg">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Icon color="darkText" as={<Box><FaCalendarAlt/></Box>} size={5}></Icon>
              </Box>
              <Box flex={1} ml={1}>
                <Text color="darkText" fontWeight={500} >
                  {/* {moment(data.date_realisasi, "YYYY-MM-DD HH:mm:ss").format("dddd, DD MMM YYYY")} */}
                  Rabu, 14 September 2024
                </Text>
              </Box>
              <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                  <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                    return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                            <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.400" />
                          </Pressable>;
                  }}>
                    {/* {access.includes('10:VIEW') && */}
                    <Menu.Item p={1} onPress={() => history.push('approval-form')}>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                        <Text color="darkText" fontSize="md">
                          View
                        </Text>
                      </HStack>
                    </Menu.Item>
                    {/* } */}
                    {/* {(access.includes('10:EDIT') && cardType != 3) && */}
                    <Menu.Item p={1} onPress={() => history.push('approval-form')}>
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                        <Text color="darkText" fontSize="md">
                          Proses Aproval
                        </Text>
                      </HStack>
                    </Menu.Item>
                    {/* } */}
                    {/* {(access.includes('10:ADD') && cardType != 3) &&
                    <Menu.Item p={1} onPress={() => actionApprove(data)}> 
                      <HStack space={2} justifyContent="center" alignItems="center">
                        <Icon as={<Box><MdAddTask/></Box>} size="md" color="info.700" />
                        <Text color="darkText" fontSize="md">
                          Approve Pemeliharaan
                        </Text>
                      </HStack>
                    </Menu.Item>
                    } */}
                  </Menu>
                </Box>
            </Stack>
          </Box>
          <Box p={5}>
            <VStack>
            <Flex flexDirection="row">
                <Box>
                  <Center>
                    <Avatar size="md" bg="#B8ECF7" mr="1">
                      <Icon as={<Box><FaRegClock/></Box>} size={6} color="#22C2E5" />
                    </Avatar>
                  </Center>
                </Box>
                <VStack justifyContent="center" width="90%">
                  <Text ml={3} fontSize="lg" color="darkText" isTruncated width="80%" fontWeight="bold">
                    {/* {data.nm_proses} */}
                    Permintaan Unit / Fakultas
                  </Text>
                </VStack>
              </Flex>
              <Flex flexDirection="row" mt={4}>
                <Box>
                  <Center>
                    <Avatar size="md" bg="#B8ECF7" mr="1">
                      <Icon as={<Box><FaRegBuilding/></Box>} size={6} color="#22C2E5" />
                    </Avatar>
                  </Center>
                </Box>
                <VStack justifyContent="center" width="90%">
                  <Text ml={3} fontSize="lg" color="darkText" isTruncated width="80%" fontWeight="bold">
                    {/* {data.nm_gedung} */}
                    Fakultas Kedokteran
                  </Text>
                  {/* <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                    adadad
                  </Text> */}
                </VStack>
              </Flex>
              <Flex flexDirection="row" mt={4}>
                <Box>
                  <Center>
                    <Avatar size="md" bg="#B8ECF7" mr="1">
                      <Icon as={<Box><FaRegUser/></Box>} size={6} color="#22C2E5" />
                    </Avatar>
                  </Center>
                </Box>
                <VStack width="90%">
                  <Text ml={3} fontSize="lg" color="darkText" isTruncated width="80%" fontWeight="bold">
                    {/* {data.nm_ruangan} */}
                    dr. Indra Gunawan, Sp.PD
                  </Text>
                  <Text ml={3} fontSize="sm" color="muted.500" isTruncated width="80%">
                    {/* {data.nm_lantai} */}
                    081280123123
                  </Text>
                </VStack>
              </Flex>
              <Flex flexDirection="row" mt={4}>
                <Box>
                  <Center>
                    <Avatar size="md" bg="white" mr="1">
                      <Icon as={<Box><FaRegEdit/></Box>} size={6} color="#22C2E5" />
                    </Avatar>
                  </Center>
                </Box>
                <VStack width="90%" justifyContent="center">
                  <Text ml={3} fontSize="sm" color="muted.700" width="80%">
                    {/* {data.hp} */}
                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                  </Text>
                </VStack>
              </Flex>
              {/* <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
                <HStack>
                  <Flex flex={1} p={2}>
                    <Flex flexDirection="row" alignItems="center">
                      <Image source={require('../../images/logo.png')} size="2xs" alt='Logo' />
                      <Text ml={2} color="darkText" isTruncated maxW="140" width="80%" fontWeight="bold">Ruang Toraja oisdosifisfo sdfsdffdsf</Text>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                      <HStack alignItems="center">
                        <Text fontWeight="500" color="muted.500" mx={2}>No Tugas</Text>
                        <Box borderRadius="lg" backgroundColor="amber.500" height="10" justifyContent="center" alignItems="center">
                          <Text color="white" fontWeight="bold" fontSize="lg" m={2} alignSelf="center">101</Text>
                        </Box>
                      </HStack>
                  </Flex>
                </HStack>
              </Box>
              <Box mt={5} backgroundColor="light.200" borderRadius="lg" width="100%">
                <HStack>
                  <Flex flex={1} p={2}>
                    <Flex flexDirection="row" alignItems="center">
                      <Image source={require('../../images/logo.png')} size="2xs" alt='Logo' />
                      <Text ml={2} color="darkText" isTruncated maxW="300" width="90%" fontWeight="bold">{`Ruangan Toraja`}</Text>
                    </Flex>
                  </Flex>
                </HStack>
              </Box> */}
              
            </VStack>
          </Box>
        </VStack>
    </Box>
  );
}

export default memo(CardApprovalList);