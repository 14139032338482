import React, { Component, useState, useEffect, forwardRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectPostAnnounceFormScreen from './selector';
import reducer from './reducer';
import saga from './saga';

import {
  AspectRatio,
  Icon,
  HStack, 
  Avatar, 
  Heading, 
  FormControl,
  Input,
  TextArea,
  VStack, 
  Box, 
  Center,
  Text,
  Image, 
  Flex,
  ScrollView, 
  Button,
  CheckIcon,
  Select,
  Stack,
  Menu,
  Pressable,
  Fab} from 'native-base';
import {FaInfoCircle, FaUserCircle} from 'react-icons/fa';
import { ContainerPage } from '../../components/ContainerPage';
import { commonStyle } from '../../styles';
import { getPegawai, submitPegawai, updateInput } from './actions';
import api from '../../util/api';
import helper from '../../util/helper';
import { MdCalendarToday, MdDelete, MdFileCopy, MdModeEdit, MdMoreVert, MdRemoveRedEye } from 'react-icons/md';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import BadgeStatus from '../../components/BadgeStatus';
import ImageSlider from '../../components/ImageSlider';
import { AiOutlinePlus } from 'react-icons/ai';
import history from '../../util/history';

export function PostAnnounceFormScreen({
  dispatch,
  location,
  postAnnounceFormScreen,
  onUpdateInput,
  onGetPegawai,
  onSubmitPegawai
}){
  const {id_pengumuman, viewOnly, new_data} = location.state;
  const[userData, setUserData] = useState();
  const [screenAccess, setScreenAccess] = useState([]);

  const imageList = [
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
    {path: '../../images/pintu.jpg'},
  ];

  useEffect(() => {
    const menus = api.getUserPrivileges();
    const allAccess = helper.generatePrivileges(menus);
    setScreenAccess(allAccess);
  },[]);

  const handleSubmit = () => {
    if(helper.isEmpty(postAnnounceFormScreen.email) || helper.isEmpty(postAnnounceFormScreen.username)){
      helper.warningToast("Lengkapi semua data yang diperlukan");
      return;
    }
    onSubmitPegawai();
  }

  const InputDate = forwardRef(({ value, onClick }, ref) => (
    <Input 
      value={moment(postAnnounceFormScreen.birth_date).format("DD/MM/YYYY")} 
      isReadOnly="true"
      onFocus={onClick} 
      borderRadius="lg" 
      variant="filled"
      ref={ref} 
      borderColor="primary.900" 
      InputRightElement={<Icon as={<Box><MdCalendarToday/></Box>} size={5} mr="2" color="muted.400" />} 
      />
  ));

  const onChangeDt = (selectedDate) => {
    const currentDate = selectedDate;
    onUpdateInput("birth_date", currentDate);
  };

  return (
        <ContainerPage options={{statusBar: true, prevPage: 'post-announce', title: 'Kirim Pengumuman'}}>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <Flex>
              {/* <Box mt={4}>
                <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <Box shadow={3} borderColor="amber.100" borderRadius={20}>
                    <Image source={{
      uri: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
    }} borderRadius={20} alt='Room' size="lg"  />
                  </Box>
                  <Box ml={3}>
                    <Button variant="outline" colorScheme="primary" leftIcon={<Icon as={MaterialIcons} name="add-a-photo" size="sm" />}>Ganti Foto Profil</Button>
                  </Box>
                </Flex>
              </Box> */}
              <Box mx={3} mt={5} p={4} backgroundColor="white" shadow={3} borderRadius="lg">
                <HStack space={2}>
                  <Icon as={<Box><FaInfoCircle/></Box>} size="5" mt="0.5" color="muted.400" />
                  <Text color="darkText" fontSize="md">
                    Form Pengumuman
                  </Text>
                </HStack>
                <Box>
                  <VStack space={2} mt={2}>
                    <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Pengumuman</FormControl.Label>
                          <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={postAnnounceFormScreen.birth_date} onChange={(date) => onChangeDt(date)} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl mb="1" isRequired>
                          <FormControl.Label>Tanggal Expired</FormControl.Label>
                          <DatePicker customInput={<InputDate/>} portalId='root-portal' className='input-datepicker' popperClassName='pooper-datepicker' selected={postAnnounceFormScreen.birth_date} onChange={(date) => onChangeDt(date)} />
                        </FormControl>
                    </Box>
                    <Box>
                      <FormControl mb="1">
                        <FormControl.Label>Deskripsi</FormControl.Label>
                        <TextArea h="64" value={postAnnounceFormScreen.address} onChangeText={txt => onUpdateInput('address', txt)} placeholder="Isi Pengumuman" borderRadius="lg" w="100%" />
                      </FormControl>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              
              <Box mx={3} mt={5}>
                  <Accordion>
                  {[...Array(3)].map((x, i) => (
                    <AccordionItem key={i} header={
                      <Box px={5} py={3} backgroundColor={commonStyle.cardBaseHeaderBg}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Icon color="darkText" as={<Box><MdFileCopy/></Box>} size={5}></Icon>
                        </Box>
                        <Box flex={1} ml={1} alignItems="start">
                          <Text color="darkText" fontWeight={500} >
                            {/* {moment(data.date_report, "YYYY-MM-DD HH:mm:ss").format("DD MMM YYYY HH:mm")} */}
                            Detail Permintaan
                          </Text>
                        </Box>
                        <Box ml={3}>
                          <BadgeStatus status="Created"/>
                        </Box>
                        <Box ml={2} alignItems="center" justifyContent="center" width={8} height={8}>
                          <Menu mt={2} py={2} placement="bottom right" trigger={triggerProps => {
                            return <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                                    <Icon as={<Box><MdMoreVert/></Box>} size={8} color="gray.600" />
                                  </Pressable>;
                          }}>
                            {/* {access.includes('05:VIEW') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdRemoveRedEye/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  View
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            {/* {access.includes('05:EDIT') &&  */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdModeEdit/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Update Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                            {/* } */}
                            <Menu.Item p={1} onPress={() => {}}>
                              <HStack space={2} justifyContent="center" alignItems="center">
                                <Icon as={<Box><MdDelete/></Box>} size="md" color="info.700" />
                                <Text color="darkText" fontSize="md">
                                  Hapus Permintaan
                                </Text>
                              </HStack>
                            </Menu.Item>
                          </Menu>
                        </Box>
                      </Stack>
                    </Box>
                    } initialEntered>
                      <VStack backgroundColor="white" p={3}>
                        <FormControl mb="1">
                          <FormControl.Label>Unit</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">Fakultas Kedokteran</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Lokasi</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text textAlign="left">TPS Kampus C Unair</Text>
                          </Box>
                        </FormControl>
                        <FormControl mb="1">
                          <FormControl.Label>Noted</FormControl.Label>
                          <Box borderRadius="md" backgroundColor="gray.100" p={3}>
                            <Text>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s</Text>
                          </Box>
                        </FormControl>
                      </VStack>
                    </AccordionItem>
                  ))}
                  </Accordion>
                </Box>
                {/* {!viewOnly && */}
                <HStack justifyContent="space-around" mb="32">
                    <Button w="1/3" isLoading={postAnnounceFormScreen.loading} spinnerPlacement="end" isLoadingText="Submitting..." colorScheme="primary" onPress={() => handleSubmit()}>Kirim</Button>
                    <Button variant="link" colorScheme="primary">Cancel</Button>
                </HStack>
                {/* } */}
            </Flex>
            
          </ScrollView>

          {/* {screenAccess.includes("05:ADD") &&  */}
          <Fab onPress={() => history.push('post-announce-form-detail')} renderInPortal={false} shadow={2} size="lg" icon={<Icon color="white" as={<Box><AiOutlinePlus/></Box>} size="lg" />} />
          {/* } */}
        </ContainerPage>
      );
    
}

const styles = {
  errorTextStyle: {
    color: 'red',
    fontStyle: 'italic',
    fontWeight: 'bold',
    alignSelf: 'center'
  }
};

const mapStateToProps = createStructuredSelector({
  postAnnounceFormScreen: makeSelectPostAnnounceFormScreen(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetPegawai: () => dispatch(getPegawai()),
    onSubmitPegawai: () => dispatch(submitPegawai()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(PostAnnounceFormScreen);