import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import api from '../util/api';
import helper from '../util/helper';
import PageNotFound from '../components/PageNotFound';
import CameraScreen from '../components/CameraScreen';

import GlobalVar from '../util/globalVar';
import LoginScreen from './LoginScreen/index';
import ForgetPassScreen from './ForgetPassScreen/index';
import WelcomeScreen from './WelcomeScreen/index';
import HomeScreen from './HomeScreen/index';
import ProfileScreen from './ProfileScreen/index';
import FormProfileScreen from './FormProfileScreen/index';
import FormChangePassScreen from './FormChangePassScreen/index';
import NotificationScreen from './NotificationScreen/index';
import NotificationDetailScreen from './NotificationDetailScreen/index';
import TaskScannerScreen from './TaskScannerScreen/index';
import UnitRequestScreen from './UnitRequestScreen/index';
import UnitRequestFormScreen from './UnitRequestFormScreen/index';
import UnitRequestFormDetailScreen from './UnitRequestFormDetailScreen/index';
import PostAnnounceScreen from './PostAnnounceScreen/index';
import PostAnnounceFormScreen from './PostAnnounceFormScreen/index';
import PostAnnounceFormDetailScreen from './PostAnnounceFormDetailScreen/index';
import UnitAnnounceScreen from './UnitAnnounceScreen/index';
import UnitAnnounceFormScreen from './UnitAnnounceFormScreen/index';
import UnitAnnounceFormDetailScreen from './UnitAnnounceFormDetailScreen/index';
import WasteTransferScreen from './WasteTransferScreen/index';
import WasteTransferFormScreen from './WasteTransferFormScreen/index';
import WasteTransferFormDetailScreen from './WasteTransferFormDetailScreen/index';
import WasteAcceptanceScreen from './WasteAcceptanceScreen/index';
import WasteAcceptanceFormScreen from './WasteAcceptanceFormScreen/index';
import WasteAcceptanceFormDetailScreen from './WasteAcceptanceFormDetailScreen/index';
import ManifestScreen from './ManifestScreen/index';
import ManifestDetailScreen from './ManifestDetailScreen/index';
import WasteUnitScreen from './WasteUnitScreen/index';
import WastePickupScreen from './WastePickupScreen/index';
import WastePickupFormScreen from './WastePickupFormScreen/index';
import WastePickupFormDetailScreen from './WastePickupFormDetailScreen/index';
import ApprovalScreen from './ApprovalScreen/index';
import ApprovalFormScreen from './ApprovalFormScreen/index';


const PrivateRoute = ({
    pageId,
    pageName,
    pageDesc,
    loadMap,
    component: Component,
    ...rest
  }) => {
    let isAuthenticated = false;
    let isGranted = true;
    //check authorization
    const token = api.getToken();
    if ( token != null) isAuthenticated = true;
    else isAuthenticated = false;
  
    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated && isGranted) {
            return (
              <>
                <GlobalVar/>
                <Component {...props} />
              </>
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  };

  export default function Main() {
  
    useEffect(() => {
      // code here
    }, []);
  
    return (
      <>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/forgetpass" component={ForgetPassScreen} />
          <PrivateRoute exact path="/welcome" component={WelcomeScreen} />
          <PrivateRoute exact path="/home" component={HomeScreen} />
          <PrivateRoute exact path="/profile" component={ProfileScreen} />
          <PrivateRoute exact path="/changepass" component={FormChangePassScreen} />
          <PrivateRoute exact path="/update-profile" component={FormProfileScreen} />

          <PrivateRoute exact path="/scanner" component={TaskScannerScreen} />
          
          <PrivateRoute exact path="/notif" component={NotificationScreen} />
          <PrivateRoute exact path="/notif-detail" component={NotificationDetailScreen} />

          <PrivateRoute exact path="/unit-request" component={UnitRequestScreen} />
          <PrivateRoute exact path="/unit-request-form" component={UnitRequestFormScreen} />
          <PrivateRoute exact path="/unit-request-form-detail" component={UnitRequestFormDetailScreen} />
          
          <PrivateRoute exact path="/post-announce" component={PostAnnounceScreen} />
          <PrivateRoute exact path="/post-announce-form" component={PostAnnounceFormScreen} />
          <PrivateRoute exact path="/post-announce-form-detail" component={PostAnnounceFormDetailScreen} />

          <PrivateRoute exact path="/unit-announce" component={UnitAnnounceScreen} />
          <PrivateRoute exact path="/unit-announce-form" component={UnitAnnounceFormScreen} />
          <PrivateRoute exact path="/unit-announce-form-detail" component={UnitAnnounceFormDetailScreen} />

          <PrivateRoute exact path="/waste-transfer" component={WasteTransferScreen} />
          <PrivateRoute exact path="/waste-transfer-form" component={WasteTransferFormScreen} />
          <PrivateRoute exact path="/waste-transfer-form-detail" component={WasteTransferFormDetailScreen} />

          <PrivateRoute exact path="/waste-accept" component={WasteAcceptanceScreen} />
          <PrivateRoute exact path="/waste-accept-form" component={WasteAcceptanceFormScreen} />
          <PrivateRoute exact path="/waste-accept-form-detail" component={WasteAcceptanceFormDetailScreen} />

          <PrivateRoute exact path="/waste-pickup" component={WastePickupScreen} />
          <PrivateRoute exact path="/waste-pickup-form" component={WastePickupFormScreen} />
          <PrivateRoute exact path="/waste-pickup-form-detail" component={WastePickupFormDetailScreen} />

          <PrivateRoute exact path="/manifest" component={ManifestScreen} />
          <PrivateRoute exact path="/manifest-detail" component={ManifestDetailScreen} />

          <PrivateRoute exact path="/waste-unit" component={WasteUnitScreen} />

          <PrivateRoute exact path="/approval" component={ApprovalScreen} />
          <PrivateRoute exact path="/approval-form" component={ApprovalFormScreen} />

          <Route path="*" component={PageNotFound} />
          {/* <PrivateRoute
            exact
            path="/welcome"
            component={WelcomeScreen}
            pageName="WELCOME PAGE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          />
          <PrivateRoute
            exact
            path="/home"
            component={HomeScreen}
            pageName="HOMEPAGE"
            pageDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            pageId=""
          /> */}
        </Switch>
      </>
    );
  }