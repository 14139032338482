import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectWasteTransferFormDetailScreen from './selector';
  import { getDetailFail, getDetailSuccess, submitTransferFail, submitTransferSuccess } from './actions';
  
  export function* doGetDetail(action) {
    const params = {
      id_transfer: action.id_transfer,
      id_approval: action.id_approval,
      jns_approval_code: action.approval_code,
    }
    try {
      const response = yield call(api.unit.getTransferDetail, params);
      const {success} = response.data;
      yield put(
        getDetailSuccess(success),
      );
      console.log("form-res:", success);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getDetailFail(err));
    }
  }

  export function* doSubmitTransfer(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWasteTransferFormDetailScreen());
    try {
      const {dtTrfLimbah, dtApproval} = dataset.dtTransferDetail;
      let idxApvKasie = 0;
      if(helper.isEmpty(dataset.id_approval_detail)){
        idxApvKasie = dtApproval.approvalkasie_detail.findIndex(
          result => result.id === dataset.id_detail,
        );
      } else {
        idxApvKasie = dtApproval.approvalkasie_detail.findIndex(
          result => result.id === dataset.id_approval_detail,
        );
      }

      const params = {
          id_approval: dataset.id_approval,
          id_vendor: dtApproval.id_vendor,
          id_approval_detail: dtApproval.approvalkasie_detail[idxApvKasie].id,
          id_approval_request: dtApproval.approvalkasie_detail[idxApvKasie].id_approval_request,
          id_unit_asal: dtApproval.id_unit_tujuan,
          id_unit_tujuan: dtApproval.id_unit_tps,
          id_ba: dtApproval.approval_ba?.id,
          no_kendaraan: dataset.no_kendaraan,
          id_jenis_limbah: dataset.id_jenis_limbah,
          code_limbah: dataset.jenis_limbah?.code,
          volume: dataset.volume,
          berat: dataset.berat,
          id: dataset.id_transfer,
          noted: dataset.description,
          transfer_attachment: JSON.stringify(dataset.assets),
      };

      // console.log(params);

      const response = yield call(api.unit.submitTransfer, params);
      yield put(
        submitTransferSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);
      
      setTimeout(() => {
        history.replace('waste-transfer-form', dataset.locationState); 
      }, 500);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitTransferFail(err));
    }
  }

  // Individual exports for testing
  export default function* wasteTransferFormDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL, doGetDetail),
      takeLatest(constants.SUBMIT_TRANSFER, doSubmitTransfer),
    ]);
  }
  