import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import makeSelectGlobalVar from './selector';
import {getListUnit, getPegawai, getVendor, getWasteType, updateInput } from './actions';
import api from './api';
import helper from './helper';

export function GlobalVar({
  dispatch,
  globalVar,
  onUpdateInput,
  onGetWasteType,
  onGetListUnit,
  onGetVendor,
  onGetPegawai
}){

  useEffect(() => {
    onGetWasteType();
    onGetListUnit();
    onGetVendor();
  },[]);

  return (
        <></>
      );
    
}

const mapStateToProps = createStructuredSelector({
  globalVar: makeSelectGlobalVar(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onUpdateInput: (name, value) => dispatch(updateInput({ name, value })),
    onGetWasteType: () =>  dispatch(getWasteType()),
    onGetListUnit: () =>  dispatch(getListUnit()),
    onGetPegawai: () =>  dispatch(getPegawai()),
    onGetVendor: () =>  dispatch(getVendor()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect
)(GlobalVar);