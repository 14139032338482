import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the UnitAnnounceFormScreen state domain
 */

const selectUnitAnnounceFormScreenDomain = state =>
  state.unitRequestFormScreen || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UnitAnnounceFormScreen
 */

const makeSelectUnitAnnounceFormScreen = () =>
  createSelector(
    selectUnitAnnounceFormScreenDomain,
    substate => substate,
  );

export default makeSelectUnitAnnounceFormScreen;
export { selectUnitAnnounceFormScreenDomain };
