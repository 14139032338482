import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import api from '../../util/api';
  import helper from '../../util/helper';
  import history from '../../util/history';
  // import * as RootNavigation from '../../../RootNavigation';
  
  import makeSelectWasteAccpetanceFormDetailScreen from './selector';
  import { getDetailPenerimaanFail, getDetailPenerimaanSuccess, submitPenerimaanFail, submitPenerimaanSuccess } from './actions';
import moment from 'moment';
  
  export function* doGetDetail(action) {
    const params = {
      id_transfer: action.id_transfer,
      id_penerimaan: action.id_penerimaan
    };

    try {
      const response = yield call(api.tps.getPenerimaanDetail, params);
      const {success} = response.data;
      yield put(
        getDetailPenerimaanSuccess(success),
      );
      console.log("form-res:", success);
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      yield put(getDetailPenerimaanFail(err));
    }
  }

  export function* doSubmitPenerimaan(action) {
    const {id_branch, origin_screen} = action;
    const dataset = yield select(makeSelectWasteAccpetanceFormDetailScreen());
    const {dtTransfer, dtDetail} = dataset;
    try {
      const params = {
          id_transfer: dataset.id_transfer,
          id_unit_asal: dtTransfer.id_unit_asal,
          id_tps_penerima: dtTransfer.id_unit_tujuan,
          id_approval: dtTransfer.id_approval,
          id_ba: dtTransfer.id_ba,
          tgl_penerimaan: moment().format("YYYY-MM-DD HH:mm:ss"),
          id_approval_detail: dtDetail.id_approval_detail,
          id_approval_request: dtDetail.id_approval_request,
          id_transfer_limbah_detail: dataset.id_detail,
          id_jenis_limbah: dataset.id_jenis_limbah,
          code_limbah: dataset.jenis_limbah?.code,
          volume: dataset.volume,
          berat: dataset.berat,
          id: dataset.id_penerimaan,
          noted: dataset.noted,
          penerimaan_attachment: JSON.stringify(dataset.assets),
      };

      console.log(params);

      const response = yield call(api.tps.submitPenerimaanDetail, params);
      yield put(
        submitPenerimaanSuccess(response.data),
      );
      helper.successToast("Data berhasil disimpan");
      console.log("res:", response.data);

      setTimeout(() => {
        history.replace('waste-accept-form', dataset.locationState); 
      }, 500);
  
    } catch (err) {
      console.log(err);
      helper.errorToast("Gagal Menyimpan data, silahkan hub admin");
      yield put(submitPenerimaanFail(err));
    }
  }

  // Individual exports for testing
  export default function* wasteAcceptanceFormDetailScreenSaga() {
    yield all([
      takeLatest(constants.GET_DETAIL, doGetDetail),
      takeLatest(constants.SUBMIT_PENERIMAAN, doSubmitPenerimaan),
    ]);
  }
  