import {
    call,
    put,
    select,
    takeLatest,
    takeEvery,
    all,
    delay
  } from 'redux-saga/effects';
 
  import * as constants from './constants';
  import moment from 'moment';
  import api from '../../util/api';
  import helper from '../../util/helper';
  
  import makeSelectPostAnnounceScreen from './selector';
import { getMorePostAnnounceFail, getMorePostAnnounceSuccess, getPostAnnounceFail, getPostAnnounceSuccess } from './actions';
  
  export function* doGetListPostAnnounce(action) {
    const dataset = yield select(makeSelectPostAnnounceScreen());
    try {
      const tglAwal = moment(dataset.start_dt).format("YYYY-MM-DD");
      const tglAkhir = moment(dataset.end_dt).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        tgl_awal: tglAwal+' 00:00:00',
        tgl_akhir: tglAkhir,
        search: dataset.q,
        status: dataset.filter_status
      };

      if(helper.isEmpty(dataset.start_dt)) delete params.date_start;
      if(helper.isEmpty(dataset.end_dt)) delete params.date_end;
      if(helper.isEmpty(dataset.q)) delete params.search;
      if(helper.isEmpty(dataset.filter_status)) delete params.status;

      const response = yield call(api.announce.getRequestList, params, dataset.page);
      const {success} = response.data;
      console.log("res:", success);
      if(dataset.page == 1){
        yield put(
          getPostAnnounceSuccess((helper.isEmpty(success) ? [] : success)),
        );
      } else {
        yield put(
          getMorePostAnnounceSuccess((helper.isEmpty(success) ? [] : success)),
        );
      }
    } catch (err) {
      console.log(err);
      helper.errorToast("Koneksi server gagal");
      if(dataset.page == 1){
        yield put(getPostAnnounceFail(err));
      } else {
        yield put(getMorePostAnnounceFail(err));
      }
    }
  }

  // Individual exports for testing
  export default function* vendorProgressScreenSaga() {
    yield all([
      takeLatest(constants.GET_POST_ANNOUNCE, doGetListPostAnnounce),
      takeLatest(constants.GET_MORE_POST_ANNOUNCE, doGetListPostAnnounce),
    ]);
  }
  