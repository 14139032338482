export const DEFAULT_ACTION = 'screen/WastePickupFormDetailScreen/DEFAULT_ACTION';
export const UPDATE_INPUT = 'screen/WastePickupFormDetailScreen/UPDATE_INPUT';

export const GET_DETAIL_TODO = 'screen/WastePickupFormDetailScreen/GET_DETAIL_TODO';
export const GET_DETAIL_TODO_LOADING = 'screen/WastePickupFormDetailScreen/GET_DETAIL_TODO_LOADING';
export const GET_DETAIL_TODO_SUCCESS = 'screen/WastePickupFormDetailScreen/GET_DETAIL_TODO_SUCCESS';
export const GET_DETAIL_TODO_FAIL = 'screen/WastePickupFormDetailScreen/GET_DETAIL_TODO_FAIL';

export const ADD_IMAGE = 'screen/WastePickupFormDetailScreen/ADD_IMAGE';
export const DEL_IMAGE = 'screen/WastePickupFormDetailScreen/DEL_IMAGE';

export const SUBMIT_PREVENTIF = 'screen/WastePickupFormDetailScreen/SUBMIT_PREVENTIF';
export const SUBMIT_PREVENTIF_LOADING = 'screen/WastePickupFormDetailScreen/SUBMIT_PREVENTIF_LOADING';
export const SUBMIT_PREVENTIF_SUCCESS = 'screen/WastePickupFormDetailScreen/SUBMIT_PREVENTIF_SUCCESS';
export const SUBMIT_PREVENTIF_FAIL = 'screen/WastePickupFormDetailScreen/SUBMIT_PREVENTIF_FAIL';