/*
 *
 * WastePickupFormDetailScreen reducer
 *
 */
import {produce} from 'immer';
import { DEFAULT_ACTION } from './constants';
import * as constants from './constants';
import helper from '../../util/helper';

export const initialState = {
    error: 0,
    error_msg: '',
    loading: false,
    loading_submit: false,
    isSuccess: false,
    dtTodo: null,
    nm_aset: "",
    nm_ruangan: "",
    description: "",
    preventif: null,
    assets:[],
    id_condition: "2",
    id_preventif_exec: null,
    id_inventaris: null,
    id_realisasi_preventif: null,
    id_ruang: null,
};

/* eslint-disable default-case, no-param-reassign */
const WastePickupFormDetailScreenReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DEFAULT_ACTION:
      return {...initialState};
      case constants.UPDATE_INPUT:
        draft[action.name] = action.value;
        break;
      
      case constants.GET_DETAIL_TODO:
        draft.loading = true;
        break;
      case constants.GET_DETAIL_TODO_FAIL:
        draft.error = true;
        draft.loading = false;
        draft.dtTodo = null;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.GET_DETAIL_TODO_SUCCESS:
        if(!helper.isEmpty(action.results.data)){
          const objTodo = action.results.data;
          draft.dtTodo = objTodo;
          draft.nm_ruangan = objTodo.nama_ruang;
          draft.nm_aset = objTodo.nama_inventaris;
          draft.id_inventaris = objTodo.id_inventaris;
          draft.id_preventif_exec = objTodo.id_preventif_exec;
          draft.id_condition = (objTodo.result_condition ? objTodo.result_condition.toString() : "2");
          draft.description = objTodo.result_description;
          if(!helper.isEmpty(objTodo.attachments)) draft.assets = objTodo.attachments;
        }
        draft.error = false;
        draft.loading = false;
        break;
      case constants.ADD_IMAGE: {
        let cur_arr = draft.assets;
        const image = action.asset;
        cur_arr.push({path: image, status: true});
        draft.assets = cur_arr;
        }
        break;
      case constants.DEL_IMAGE:
        draft.assets = [...draft.assets.slice(0, action.idx), ...draft.assets.slice(action.idx + 1)];
        break;
      case constants.SUBMIT_PREVENTIF:
        draft.loading_submit = true;
        break;
      case constants.SUBMIT_PREVENTIF_FAIL:
        draft.error = true;
        draft.loading_submit = false;
        draft.error_msg= 'Maaf, Hasil pencarian belum ditemukan.';
        break;
      case constants.SUBMIT_PREVENTIF_SUCCESS:
        draft.preventif = action.results;
        draft.loading_submit = false;
        draft.isSuccess = true;
        draft.error = false;
        break;
    }
  });

export default WastePickupFormDetailScreenReducer;
